.modal-title{
  color: #ff5006;
}

.add-category-footer {
  display: flex;
  justify-content: center;
  padding: 3% 0;
}


.dollar{
	display:inline-block;
	position: relative;
	}
.dollar input{
	padding-left:15px;
	}
.dollar:before {
	position: absolute;
    content:"$";
    left:5px;
	top:2px;
 	}