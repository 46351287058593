@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader {
  color: #ff5006;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  top: 50%;
  margin-left: 23%;
  z-index: 9999999;
  position: fixed;
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  height: 100vh;
  width: 100vw;
}

.img-bg {
  background-image: url(/static/media/background.c46c3d36.jpg);
  background-color: rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
}

.logo {
  width: 460px;
}

.lead {
  font-family: "Open Sans", sans-serif;
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.95px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 50px;
}

.sub-lead {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: -0.6px;
  text-align: center;
  color: #ffffff;
  max-width: 900px;
  margin: auto;
}

hr {
  display: block;
  height: 2px;
  max-width: 900px;
  justify-self: center;
  border: 0;
  border-top: 2px solid #ccc;
  padding: 0;
}

.title {
  margin-top: 10%;
  font-family: "Open Sans", sans-serif;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.85px;
  text-align: center;
  color: #5b5b5b;
}

.subtitle {
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.22px;
  color: #ff5007;
}

.icon {
  height: 65px;
}

.icon-label {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  color: #3b3d40;
  text-transform: uppercase;
  margin-top: 20px;
}

.m-top {
  margin-top: 100px !important;
}

.btn-secondary {
  border: hidden;
  border-radius: 5px;
  padding: 0.6rem 1.2rem;
  font-size: 16px;
  text-transform: uppercase;
  margin: 1rem;
  background-color: #ff5007;
  color: white;
  margin-top: 100px;
}
.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #ff783e;
  color: white;
}

.foot {
  margin-top: -30px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  text-align: left;
  color: #505050;
}

@media (max-width: 768px) {
  .logo {
    width: 250px;
  }
  .lead {
    font-size: 24px;
  }
  .sub-lead {
    font-size: 18px;
  }
  .title {
    font-size: 24px;
    /* margin-top: 20px; */
  }
  .subtitle {
    font-size: 16px;
  }
  .icon {
    height: 50px;
  }
  .icon-label {
    font-size: 8px;
  }
  .m-top {
    margin-top: 50px !important;
  }
  .btn-secondary {
    font-size: 12px;
  }
}

/*============ BOOTSTRAP BREAK POINTS:

Extra small (xs) devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap

Small (sm) devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

Medium (md) devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

Large (lg) devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

Extra (xl) large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }

=============*/

.progress-container{
  padding-top: 3%;
  opacity: 0.55;
}

.progress-step{
  display: inline-block;
  text-align: center;
}

@media only screen and (max-width: 1024px){
  .appContainer{
    width: 95% !important;
  }

  .logo-header{
    display: none !important;
  }

  ul{
    display: flex;
  }

  ul:before, .active:after {
    display: none;
  }

  .app-container-header{
    justify-content: center !important;
  }

  .priceInput{
    width: 100%;
  }

  .progress-step{
    font-size: 13px;
  }
}

.currencyInput{
  width: 100% !important;
}

.PhoneInputInput{
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

ul {
  text-align: justify;
  position: relative;
  overflow: hidden;
}

ul:before, .active:after {
  content: '';
  width: 100%;
  border: 2px solid lightgray;
  position: absolute;
  top: 1.1em;;
  margin-top: -6px;
  z-index: -1;
}

.active{
  background: #ff5007 ;
}

.active:after {
  border-color: lightgray;
}

ul:after {
  content: "";
  display: inline-block;
  width: 100%;
}


li {
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  line-height: 1.5em;
  border-radius: 50%;
  background: lightgray;
  margin: 0 3.0em;
  display: inline-block;
  color: white;
}

.active ~ li {
  background: #ff5007;
}
.app-container-header{
  display: flex;
  justify-content: space-between;
  padding: 0 4%;
  width: 100%;
  border-bottom: 2px solid  #808080;
}



.logo-header{  
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.logo-header p {
  font-size: 16;
  color:black;
  font-weight: bold;
}

.logo-header img {
  width: 150px;
}

.location-search-input {
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 0;
  border: 1px solid lightgray;
}

.autocomplete-dropdown-container {
  z-index: 100000;
  overflow: hidden;
  border-radius: 5px;
  position: absolute;
  width: 40%;
}

@media only screen and (max-width: 1024px){
  .autocomplete-dropdown-container{
    width: 90% !important;
  }
}

.form-footer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form-group.required .control-label:after {
  content:"*";
  color:red;
}

.react-phone-number-input__icon{
  height: auto !important;
  height: initial !important;
  border: 0 !important;
}

.PhoneInputCountryIcon--border{
  height: auto !important;
  height: initial !important;
  border: 0 !important;
  background-color: transparent;
  box-shadow: none;
}

.table-category{
  margin-top: 5%;
}
.modal-title{
  color: #ff5006;
}

.add-category-footer {
  display: flex;
  justify-content: center;
  padding: 3% 0;
}
.category-container .title{
  color: #ff5006;
}

.category-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-menu{
    margin-top: 3%;
}


.list-menu-container{
  margin-top:3%;
}

.list-menu-button{
  margin-top: 5%;
  text-align: center;
}

.modal-title{
  color: #ff5006;
}

.add-category-footer {
  display: flex;
  justify-content: center;
  padding: 3% 0;
}


.dollar{
	display:inline-block;
	position: relative;
	}
.dollar input{
	padding-left:15px;
	}
.dollar:before {
	position: absolute;
    content:"$";
    left:5px;
	top:2px;
 	}
.product-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-header .title{
  color: #ff5006;
}
.add-product-footer{
  display: flex;
  justify-content: center;
  padding: 3% 0;
}
.App {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  padding: 3%;
  align-items: center;
  background: url(/static/media/easy-company.aba7ebf1.jpg) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.app-container {
  width: 60%;
  background-color: white;
  border-radius: 1%;
}

.route-container{
  display: flex;
  align-content: center;
  flex-direction: column;
  padding: 2% 3%;
}

.orange-bold{
	background: #ff5007;
	color: white;
	padding: 9px;
	font-size: 14px;
	border-radius: 5px;
	width: 25%;
	border:1px solid white;
	transition: all ease 0.3s;
}

@media only screen and (max-width: 1024px){
  .orange-bold{
    width: 80% !important;
  }
}

.orange-bold:hover{
  background: white;
  color: #ff5007;
  border: 1px solid #ff5007;
}

.gray-bold{
	background: #878787;
	color: white;
	padding: 9px;
	font-size: 14px;
	border-radius: 5px;
	width: 25%;
	border:1px solid white;
	transition: all ease 0.3s;
}

.gray-bold:hover{
  background: white;
  color: #878787;
  border: 1px solid #878787;
}

.btn-round{
	border-radius: 23px;
}


