.product-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-header .title{
  color: #ff5006;
}
.add-product-footer{
  display: flex;
  justify-content: center;
  padding: 3% 0;
}