.App {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  padding: 3%;
  align-items: center;
  background: url(assets/img/easy-company.jpg) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.app-container {
  width: 60%;
  background-color: white;
  border-radius: 1%;
}

.route-container{
  display: flex;
  align-content: center;
  flex-direction: column;
  padding: 2% 3%;
}

.orange-bold{
	background: #ff5007;
	color: white;
	padding: 9px;
	font-size: 14px;
	border-radius: 5px;
	width: 25%;
	border:1px solid white;
	transition: all ease 0.3s;
}

@media only screen and (max-width: 1024px){
  .orange-bold{
    width: 80% !important;
  }
}

.orange-bold:hover{
  background: white;
  color: #ff5007;
  border: 1px solid #ff5007;
}

.gray-bold{
	background: #878787;
	color: white;
	padding: 9px;
	font-size: 14px;
	border-radius: 5px;
	width: 25%;
	border:1px solid white;
	transition: all ease 0.3s;
}

.gray-bold:hover{
  background: white;
  color: #878787;
  border: 1px solid #878787;
}

.btn-round{
	border-radius: 23px;
}

